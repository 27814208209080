<template>
    <div class="input-container" >
        <input
            type="text"
            class="form-control"
            :class="inputClass"
            :placeholder="placeholder"
            data-bs-toggle="dropdown"
            :data-bs-target="'#dropdown-menu_' + moduleId"
            aria-expanded="false"
            ref="input"
            :disabled="disabled === true"
            @keyup.arrow-up="keyUp"
            @keyup.arrow-down="keyDown"
            @keyup.enter="keyEnter"
            @input="onChange"
            v-model="inputContent"
        />
        <ul
            class="dropdown-menu"
            :id="`dropdown-menu_${moduleId}`"
            :style="{ minWidth: width }"
        >
            <li v-for="(i, index) in items" :key="index" ref="itemRef">
                <div
                    class="dropdown-item"
                    href="#"
                    :class="{ active: indexSelected === index }"
                    @click="selectItem(index)"
                >
                    {{ displayItem(i) }}
                </div>
            </li>
        </ul>
        <svg
            v-if="showCleanBtn"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            class="dp__icon dp__clear_icon dp__input_icons"
            data-test="clear-icon"
            @click.prevent="resetInput"
        >
            <path
                d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
            ></path>
            <path
                d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
            ></path>
        </svg>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { computed, onMounted, ref, watch, nextTick } from 'vue';
import { Dropdown } from 'bootstrap';

export default {
    name: 'Autocomplete',
    props: {
        inputClass: String,
        placeholder: String,
        items: {
            type: Array,
            default: () => [],
        },
        displayItem: { type: Function, default: (item) => item },
        debounce: { type: Number, default: 500 },
        disabled: { type: [Boolean, Object], default: false },
    },
    setup(props, { emit }) {
        const timeout = ref();
        const moduleId = ref(Math.floor(Math.random() * 1000));
        const input = ref();
        const dropdown = ref();
        const itemRef = ref();
        const width = ref('10em');
        const indexSelected = ref(-1);
        const inputContent = ref('');

        const observer = new ResizeObserver(([{ target }]) => {
            width.value = `${target.offsetWidth}px`;
        });

        const setText = (text) => {
            inputContent.value = text;
        };

        onMounted(() => {
            observer.observe(input.value);
            dropdown.value = new Dropdown(input.value, {
                autoClose: true,
                display: 'static',
                reference: input.value
            });
        });

        const keyUp = () => {
            if (indexSelected.value === -1 || indexSelected.value === 0) {
                indexSelected.value = props.items.length - 1;
            } else {
                indexSelected.value -= 1;
            }
        };

        const keyDown = () => {
            if (indexSelected.value === -1 || indexSelected.value === props.items.length - 1) {
                indexSelected.value = 0;
            } else {
                indexSelected.value += 1;
            }
        };

        const selectItem = (index) => {
            setText(props.displayItem(props.items[index]));
            indexSelected.value = -1;
            emit('onSelect', props.items[index]);
            dropdown.value.hide();
        };

        const keyEnter = () => {
            if (indexSelected.value > -1 && indexSelected.value < props.items.length) {
                selectItem(indexSelected.value);
                dropdown.value.toggle();
            }
        };

        const handlerFocusLost = () => {
            dropdown.value.hide();
        };

        const handleFocus = () => {
            if (props.items.length) {
                dropdown.value.show();
            }
        };

        const onChange = () => {
            if (timeout.value === undefined) {
                timeout.value = setTimeout(() => {
                    if (input.value.value) {
                        emit('onInput', input.value.value);
                    }
                    indexSelected.value = -1;
                    timeout.value = undefined;
                }, props.debounce);
            }
        };

        watch(
            () => props.items,
            (n) => {
                if (n.length) {
                    nextTick(dropdown.value.show());
                } else {
                    nextTick(dropdown.value.hide());
                }
            }
        );

        const resetInput = () => {
            emit('onReset');
        };

        const showCleanBtn = computed(() => {
            if (inputContent.value) {
                return true;
            }
            return false;
        });

        return {
            moduleId,
            input,
            indexSelected,
            itemRef,
            width,
            keyUp,
            keyDown,
            keyEnter,
            selectItem,
            setText,
            onChange,
            showCleanBtn,
            inputContent,
            resetInput,
            handlerFocusLost,
            handleFocus,
        };
    },
};
</script>
<style lang="scss" scoped>
@import 'bootstrap/scss/bootstrap';

.input-container {
    position: relative;
    box-sizing: unset;
}

.dropdown-item:focus {
    color: black !important;
}

.dp__icon {
    stroke: currentcolor;
    fill: currentcolor;
}
.dp__clear_icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--dp-icon-color);
}
.dp__input_icons {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    stroke-width: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 6px 12px;
    color: var(--dp-icon-color);
    box-sizing: content-box;
}
</style>
